import { Button, CssVarsProvider, FormControl, FormLabel, Input, Sheet, Typography } from '@mui/joy'
import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { GameCloseError, InvalidIdError, joinGame } from './backend'
import { useAppDispatch, useAppSelector } from './store'


export const JoinPage = ()=>{
    const {joinId} = useParams()

    const [name,setName] = useState("")
    const appDispatch = useAppDispatch()
    const navigate = useNavigate()
  
    const inGame = useAppSelector((state)=>state.userInfo.inGame)

    if(!joinId){
        return <Navigate to="/"/>
    }
  
    const joinGameBtn = async () =>{
        try{
          await joinGame(appDispatch,joinId,name.trim())
          navigate("/game")
        }catch(ert){
          if(ert instanceof InvalidIdError){
            alert("Impossibile trovare la partita")
            return <Navigate to="/"/>
          }else if(ert instanceof GameCloseError){
            alert("Non è possibile entrare in una partita in corso")
            return <Navigate to="/"/>
          }else{
            throw(ert)
          }
          
        }
      }
      if(inGame){
        return <Navigate to="/game"/>
      }
    return (
        <CssVarsProvider>
          <main>
            <Sheet
              sx={{
                width: 300,
                mx: 'auto', // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
              }}
              variant="outlined"
            >
              <div>
                <Typography level="h4" component="h1">
                  <b>Ciaone!</b>
                </Typography>
                <Typography level="body-sm">Inserisci il tuo nome per entrare in partita</Typography>
              </div>
              <FormControl>
                <FormLabel>Nome</FormLabel>
                <Input
                  // html input attribute
                  name="name"
                  type="text"
                  placeholder="pippo"
                  value = {name}
                  onChange= {(e) => setName(e.target.value)}
                />
              </FormControl>
    
              <Button sx={{ mt: 1 /* margin top */ }} onClick = {joinGameBtn}>Join Game</Button>
            </Sheet>
          </main>
        </CssVarsProvider>
      );

}