import React, { useState } from "react"
import { useAppSelector } from "./store"
import { Box, Button } from "@mui/joy"
import { PlayerCard } from "./playerCard"
import { GameJoinPath, kickPlayer, startNewBallot, startNewVotes, updateGameState, updatePlayerRole, updatePlayerStatus } from "./backend"
import { gameState, mapGameStateToText } from "./gameState"
import { NarretorControls } from "./narretorControls"
import { ConfirmModal } from "./ConfirmModal"
import { getPlayerById, getVotesBoudle } from "./utils"
import { BallotModal } from "./BallotModal"
import { QRModal } from "./QRCodeModal"


export function NarretorPage(){
    const cGameState = useAppSelector((state)=>state.gameState)
    console.log("QUESTO",cGameState)
    const gameId = useAppSelector((state)=>state.userInfo.gameId)
    const roles = useAppSelector((state)=> state.gameRoles.roles)

    const [voteModalOpen,setVoteModalOpen] = useState(false)
    const [ballotModalOpen, setBallotModalOpen] = useState(false)
    const [qrModalOpen, setQrModalOpen] = useState(false)

    const joinPath = GameJoinPath + "/" + encodeURIComponent(cGameState.join_id)

    const {playerSegns, playerVotes,playerHasVoted} = getVotesBoudle(cGameState)

    let toVote = Object.keys(playerHasVoted).filter((t)=>!playerHasVoted[t])
    
    if(cGameState.lastWasBallot && cGameState.active_ballot){
        toVote = toVote.filter((t)=>{return !cGameState.ballottaggi[cGameState.active_ballot!].accusati.includes(t)})
    }


    const onUpdateState = (nState:gameState) => {
        switch(nState){
            case gameState.GSTATE_OPEN:
                updateGameState(gameId!, nState)
                break
            case gameState.GSTATE_FINE:
                updateGameState(gameId!, nState)
                break
            case gameState.GSTATE_NOTTE:
                updateGameState(gameId!, nState)
                break
            case gameState.GSTATE_VOTI:
                setVoteModalOpen(true)
                break
            case gameState.GSTATE_BALLOT:
                setBallotModalOpen(true)
                break
        }
    }


    const startVote = ()=>{
        startNewVotes(gameId!)
    }

    return (
        <div>
            <div>
                <NarretorControls cState={cGameState.state} onPushButton={onUpdateState}/>
                {cGameState.join_id} Narratore  Game State: {mapGameStateToText(cGameState.state)}
                {cGameState.active_vote?(<div>
                    Voti: {cGameState.players.length-toVote.length} / {cGameState.players.length}
                    Devono ancora votare: {toVote.map(el=>{
                        return (<div>{getPlayerById(cGameState,el)?.name}</div>)
                    })}
                </div>):null}
                <Button onClick={()=>setQrModalOpen(true)}>QR Code</Button>
            </div>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                    gap: 2,
                }}
                >
                {
                    cGameState.players.map(t=>{
                        return (<PlayerCard 
                            key={t.player_id} 
                            mplayer={t}
                            disableEdit = {!(cGameState.state === gameState.GSTATE_OPEN || cGameState.state === gameState.GSTATE_NOTTE)} 
                            isNarretor 
                            roles={roles} 
                            onChangeRole={(nRole)=>{
                                updatePlayerRole(gameId!,t.player_id,nRole.role_id)
                            }} 
                            onKick={()=>{kickPlayer(gameId!,t.player_id)}}
                            onStatusUpdate={(dead,romeo,amato)=>{updatePlayerStatus(gameId!,t.player_id,dead,romeo,amato)}}
                            votes={playerVotes[t.player_id]}
                            segns={playerSegns[t.player_id]}
                        / >)
                    })
                }
                </Box>
                <ConfirmModal 
                    open={voteModalOpen} 
                    message="Vuoi avviare un nuovo turno di accusa?" 
                    onCancel={()=>{setVoteModalOpen(false)}} 
                    onConfirm={()=>{setVoteModalOpen(false);startVote()}}
                />
                <BallotModal 
                    open={ballotModalOpen}
                    cGameState={cGameState}
                    onCancel={()=>setBallotModalOpen(false)}
                    onConfirm={(vals)=>{setBallotModalOpen(false);startNewBallot(gameId!,vals)}}
                />
                <QRModal
                    open={qrModalOpen}
                    code={joinPath}
                    onClose={()=>setQrModalOpen(false)}
                
                />
        </div>
    )
}