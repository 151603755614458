import React, { useState } from "react"
import { useAppSelector } from "./store"
import { Box, CircularProgress } from "@mui/joy"
import { PlayerCard } from "./playerCard"
import { castBallotVote, castSegn, castVote } from "./backend"
import { gameState, mapGameStateToText, roleSpecial } from "./gameState"
import { getPlayerById, getPlayerVotes, getPlayerVoteBallot, getPlayerSegn, getVotesBoudle } from "./utils"
import { ConfirmModal } from "./ConfirmModal"

export function PlayerPage(){
    const cGameState = useAppSelector((state)=>state.gameState)
    const playerId = useAppSelector((state)=>state.userInfo.playerId)!
    const gameId = useAppSelector((state)=>state.userInfo.gameId)

    const [voteTarget,setVoteTarget] = useState(null as null|string)
    const [ballotTarget,setBallotTarget] = useState(null as null|string)
    const [segnTarget,setSegnTarget] = useState(null as string|null)

    const playerVote = getPlayerVotes(cGameState,playerId!)
    const playerVoteBallot = getPlayerVoteBallot(cGameState,playerId!)
    const playerSegn = getPlayerSegn(cGameState,playerId)


    const player = getPlayerById(cGameState,playerId)

    let enableVote = false;
    const enableSegn = playerSegn === null;
    let canSegn = false;
    let segnText = "Segnala"

    let spyActive = false
    const {playerVotes} = getVotesBoudle(cGameState)

    if(player){

    
        
        spyActive = player.role !== null && player.role.special === roleSpecial.GSPECIAL_SPY && playerVote.includes(playerId) && cGameState.lastWasBallot === false
    
        if(cGameState.state === gameState.GSTATE_VOTI){
            enableVote = player.dead === false;
            //Handle mercante
            if(!player.role || player.role.special !== roleSpecial.GSPECIAL_MERC){
                enableVote = enableVote && playerVote.length === 0 
            }
            //Handle azzeccagarbugli
            if(player.role && player.role.special === roleSpecial.GSPECIAL_AZZEC){
                canSegn = true;
                segnText = "Segnala (Azzeccagarbugli)";
            }
        }else if(cGameState.state === gameState.GSTATE_BALLOT){
            const accusati = cGameState.ballottaggi[cGameState.active_ballot!].accusati
            enableVote = player.dead === false;
            enableVote = enableVote && playerVoteBallot === null;
            if(!player.role || !player.role.always_vote_ballot){
                enableVote = enableVote && !accusati.includes(playerId)
            }
            //Handle oratore
            if(player.role && player.role.special === roleSpecial.GSPECIAL_ORAT){
                canSegn = true;
                segnText = "Segnala (Oratore)";
            }
        }
    }

    



    const sendVote = async ()=>{
        setVoteTarget(null)
        await castVote(gameId!,cGameState.active_vote!,playerId!,voteTarget!)
    }

    const sendBallotVote = async ()=>{
        setBallotTarget(null)
        await castBallotVote(gameId!,cGameState.active_ballot!,playerId!,ballotTarget!)
    }

    const sendSegn = async ()=>{
        setSegnTarget(null)
        if(cGameState.lastWasBallot){
            await castSegn(gameId!,true,cGameState.active_ballot!,playerId!,segnTarget!)
        }else{
            await castSegn(gameId!,false,cGameState.active_vote!,playerId!,segnTarget!)
        }
    }
    if(!player){
        return <CircularProgress/>
    }
    return (
        <div>
            <div>
                Giocatore  Game State: {mapGameStateToText(cGameState.state)}
            </div>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                    gap: 2,
                }}
                >
                {
                    cGameState.players.map(t=>{
                        if(cGameState.state === gameState.GSTATE_VOTI){
                            return (<PlayerCard 
                                key={t.player_id}
                                mplayer={t}
                                isYou={t.player_id === playerId}
                                isVotato={playerVote.includes(t.player_id)}
                                isSegnalato={t.player_id === playerSegn}
                                isSpy={player.role !== null && player.role.special=== roleSpecial.GSPECIAL_SPY}
                                disableVote={!enableVote || playerVote.includes(t.player_id)}
                                onVote={()=>setVoteTarget(t.player_id)}
                                canSegn={canSegn}
                                disableSegn={!enableSegn}
                                segnButtonText={segnText}
                                onSegn={()=>setSegnTarget(t.player_id)}
                                votes={playerVotes[t.player_id]}
                                canSeeVotes={spyActive}
                                
                            />)
                        }else if(cGameState.state === gameState.GSTATE_BALLOT){
                            
                            const accusati = cGameState.ballottaggi[cGameState.active_ballot!].accusati
                            //console.log("t.player_id",t.player_id,accusati,t.player_id in accusati)
                            return (<PlayerCard 
                                key={t.player_id}
                                mplayer={t}
                                isYou={t.player_id === playerId}
                                isVotato={t.player_id === playerVoteBallot}
                                isAccusato={accusati.includes(t.player_id)}
                                isSegnalato={t.player_id === playerSegn}
                                disableVote={!enableVote || !accusati.includes(t.player_id)}
                                onVote={()=>setBallotTarget(t.player_id)}
                                canSegn={canSegn}
                                disableSegn={!enableSegn || !accusati.includes(t.player_id)}
                                segnButtonText={segnText}
                                onSegn={()=>setSegnTarget(t.player_id)}
                            />)
                        }else{
                            return (<PlayerCard 
                                key={t.player_id} 
                                mplayer={t}
                                isYou={t.player_id === playerId}
                                isVotato={playerVote.includes(t.player_id)}
                                isSegnalato={t.player_id === playerSegn}
                                isSpy={cGameState.lastWasBallot === false &&player.role !== null && player.role.special=== roleSpecial.GSPECIAL_SPY}
                                disableVote={true}
                                canSegn={canSegn}
                                disableSegn={!enableSegn}
                                segnButtonText={segnText}
                                onSegn={()=>setSegnTarget(t.player_id)}
                                votes={playerVotes[t.player_id]}
                                canSeeVotes={spyActive}
                            / >)
                        }

                    })
                }
                </Box>
                <ConfirmModal 
                    open={voteTarget !== null} 
                    message={`Vuoi votare per ${getPlayerById(cGameState,voteTarget!)?.name} ?`}
                    onCancel={()=>{setVoteTarget(null)}} 
                    onConfirm={()=>{sendVote()}}
                />
                <ConfirmModal 
                    open={ballotTarget !== null} 
                    message={`Vuoi votare per ${getPlayerById(cGameState,ballotTarget!)?.name} ?`}
                    onCancel={()=>{setBallotTarget(null)}} 
                    onConfirm={()=>{sendBallotVote()}}
                />
                <ConfirmModal 
                    open={segnTarget !== null} 
                    message={`Vuoi utilizzare il tuo potere (${player.role?player.role.name:"ao"}) su ${getPlayerById(cGameState,segnTarget!)?.name} ?`}
                    onCancel={()=>{setSegnTarget(null)}} 
                    onConfirm={()=>{sendSegn()}}
                />
        </div>
    )
}