import { game } from "./gameState";
import { commonVoteType, segnVoteType } from "./playerCard";

export const randomString = (length = 10) =>  {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const getPlayerVotes = (cGameState:game, playerId:string) =>{
  const ret:string[] = []
  if(cGameState.active_vote){
    const arr = cGameState.votazioni[cGameState.active_vote].voti
    for(let i = 0; i < arr.length; i++){
      if(arr[i].from === playerId){
        ret.push(arr[i].to)
      }
    }
  }
  return ret
}

export const getPlayerById = (cGameState:game, playerId:string) => {
  for(let i = 0 ; i < cGameState.players.length; i++){
    if(cGameState.players[i].player_id === playerId){
      return cGameState.players[i]
    }
  }
  return null
}

export const getPlayerVoteBallot = (cGameState:game, playerId:string) =>{
  if(cGameState.active_ballot){
    const arr = cGameState.ballottaggi[cGameState.active_ballot!].voti
    for(let i = 0; i < arr.length; i++){
      if(arr[i].from === playerId){
        return arr[i].to
      }
    }
  }
  return null;
}

export const getPlayerSegn = (cGameState:game, playerId:string) =>{
  if(cGameState.lastWasBallot){
    if(cGameState.active_ballot){
      const arr = cGameState.ballottaggi[cGameState.active_ballot!].segnalazioni
      for(let i = 0; i < arr.length; i++){
        if(arr[i].from === playerId){
          return arr[i].to
        }
      }
    }
    return null;
  }else{
    if(cGameState.active_vote){
      const arr = cGameState.votazioni[cGameState.active_vote!].segnalazioni
      for(let i = 0; i < arr.length; i++){
        if(arr[i].from === playerId){
          return arr[i].to
        }
      }
    }
    return null;
  }

}

interface voteBoundle{
  playerSegns:{[k in string]: segnVoteType[]},
  playerVotes:{[k in string]: commonVoteType[]},
  playerHasVoted:{[k in string]:boolean},
}

export const getVotesBoudle = (cGameState:game)=>{
  const playerSegns:{[k in string]: segnVoteType[]} = {}
  const playerVotes:{[k in string]: commonVoteType[]} = {}
  const playerHasVoted:{[k in string]:boolean} = {}

  if(cGameState.active_ballot && cGameState.lastWasBallot){
    cGameState.players.forEach(t=>{playerHasVoted[t.player_id] = false})
    cGameState.ballottaggi[cGameState.active_ballot].segnalazioni.forEach((elm)=>{
        const fPlayer = getPlayerById(cGameState,elm.from)!
        const voteElem = {
            playerName:fPlayer.name, 
            playerRole:fPlayer.role?fPlayer.role.name:"No Role"}
        if(elm.to in playerSegns){
            playerSegns[elm.to].push(voteElem)
        }else{
            playerSegns[elm.to] = [voteElem]
        }
    })
    cGameState.ballottaggi[cGameState.active_ballot].voti.forEach((elm)=>{
        const fPlayer = getPlayerById(cGameState,elm.from)!
        playerHasVoted[elm.from] = true
        const voteElem = {
            playerName:fPlayer.name, 
            playerRole:fPlayer.role?fPlayer.role.name:"No Role",
            sepcial:false}
        if(fPlayer.role && ((cGameState.lastWasBallot && fPlayer.role.special_ballot) || (!cGameState.lastWasBallot && fPlayer.role.special_vote))){
            voteElem.sepcial = true
        }
        if(elm.to in playerVotes){
            playerVotes[elm.to].push(voteElem)
        }else{
            playerVotes[elm.to] = [voteElem]
        }
    })
}else if(cGameState.active_vote && !cGameState.lastWasBallot){
    cGameState.players.forEach(t=>{playerHasVoted[t.player_id] = false})
    cGameState.votazioni[cGameState.active_vote].segnalazioni.forEach((elm)=>{
        const fPlayer = getPlayerById(cGameState,elm.from)!
        const voteElem = {
            playerName:fPlayer.name, 
            playerRole:fPlayer.role?fPlayer.role.name:"No Role"}
        if(elm.to in playerSegns){
            playerSegns[elm.to].push(voteElem)
        }else{
            playerSegns[elm.to] = [voteElem]
        }
    })
    cGameState.votazioni[cGameState.active_vote].voti.forEach((elm)=>{
        const fPlayer = getPlayerById(cGameState,elm.from)!
        playerHasVoted[elm.from] = true
        const voteElem = {
            playerName:fPlayer.name, 
            playerRole:fPlayer.role?fPlayer.role.name:"No Role",
            sepcial:false}
        if(fPlayer.role && ((cGameState.lastWasBallot && fPlayer.role.special_ballot) || (!cGameState.lastWasBallot && fPlayer.role.special_vote))){
            voteElem.sepcial = true
        }
        if(elm.to in playerVotes){
            playerVotes[elm.to].push(voteElem)
        }else{
            playerVotes[elm.to] = [voteElem]
        }
    })
}


  return { playerSegns,playerHasVoted,playerVotes} as voteBoundle
}