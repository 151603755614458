import React from 'react';
import { createUserLeaveAction, useAppDispatch, useAppSelector } from './store';
import { Navigate } from 'react-router-dom';
import { NarretorPage } from './NarretorPage';
import { PlayerPage } from './PlayerPage';
import { useGameStateListener } from './backend';
import { LoadGuard } from './loadGuard';
import { Box, Button } from '@mui/joy';

export function GamePage(){
    const userState = useAppSelector((state)=>state.userInfo)
    const dispatch = useAppDispatch()
    useGameStateListener()
    if(!userState.inGame){
        return <Navigate to="/"/>
    }
    return (
    <Box>
        <Button variant="solid" color="danger" onClick={()=>{dispatch(createUserLeaveAction()())}}>Esci</Button>
        <LoadGuard>
        {userState.isNarratore?<NarretorPage/>:<PlayerPage/>}
    </LoadGuard>
    </Box>);

}