// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { DocumentReference, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { firestoreBallottaggio, firestoreGame, firestorePlayer, firestoreRole, firestoreVotazione, firestoreVoto, gameState } from "./backendTypes";
import { randomString } from "./utils";
import { AppDispatch, createUserCreateAction, createUserJoinAction, createUserLeaveAction, useAppDispatch, useAppSelector } from "./store";
import { useEffect } from "react";
import { ballotMap, ballottaggio, createBallotUpdateAction, createGameSetRolesAction, createGameUpdateAction, createPlayerUpdateAction, createVotesUpdateAction, game, player, playerRole, votazione, votesMap } from "./gameState";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

//import tRoles from "./roles.json"

//const mRoles = tRoles as {[k in string]: any}



const firebaseConfig = {
  apiKey: "AIzaSyAaf3s_pTGbyNjm5xp7_baSTKARuqnc0gE",
  authDomain: "wolfapp-c897e.firebaseapp.com",
  projectId: "wolfapp-c897e",
  storageBucket: "wolfapp-c897e.appspot.com",
  messagingSenderId: "421567216385",
  appId: "1:421567216385:web:4f74afc623a23d7a523900"
};

export class InvalidIdError extends Error{}
export class GameCloseError extends Error{}

export const GameJoinPath = "wolfapp.zaxnet.it/join"

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

const gameCollection = collection(db,"games");

/*const t = async ()=>{
  const hT = Object.keys(mRoles)
  for(let i = 0; i < hT.length; i++){
    const ref = doc(db,"roles",hT[i])
    await setDoc(ref,mRoles[hT[i]])
  }
}*/

//t()
//console.log("RUOLI",mRoles)

export const newGame = async (dispatch:AppDispatch) => {
  const nGameStruct: firestoreGame = {
    join_id: randomString(10),
    state: gameState.GSTATE_OPEN,
    active_ballot: null,
    active_vote: null,
    lastWasBallot:false
  };

  const gameRef = await addDoc(gameCollection,nGameStruct)
  dispatch(createUserCreateAction(gameRef.id))
  return await getDoc(gameRef);
}

export const joinGame = async (dispatch:AppDispatch,join_id:string, name:string) =>{
  const q = query(gameCollection,where("join_id","==",join_id))
  const querySnapshot = await getDocs(q)
  console.log(`Responce size: ${querySnapshot.size}`)
  if (querySnapshot.size !== 1){
    throw new InvalidIdError("Invalid id")
  }
  const game = querySnapshot.docs[0]


  const q2 = query(collection(db,"games",game.id,"players"),where("lc_name","==",name.toLowerCase()))
  const as = await getDocs(q2)

  if(as.size > 0){
    dispatch(createUserJoinAction(game.id,as.docs[0].id))
    return game
  }

  if(game.data().state !== gameState.GSTATE_OPEN){
    throw new GameCloseError();
  }

  
  const nPlayer:firestorePlayer = {
    name,
    lc_name: name.toLowerCase(),
    amato:false,
    romeo:false,
    dead:false,
    role:null
  }

  const playerCollection = collection(db,"games",game.id,"players")

  const playerRef = await addDoc(playerCollection,nPlayer)
  dispatch(createUserJoinAction(game.id,playerRef.id))
  return game
  
}

export const updateRoles = async (dispatch:AppDispatch) => {
  const querySnapshot = await getDocs(collection(db,"roles"))
  const roles:playerRole[] = []
  querySnapshot.forEach((doc)=>{
      const mAo = doc.data() as firestoreRole
      roles.push({
        ...mAo,
        role_id: doc.id
      })
  })
  dispatch(createGameSetRolesAction(roles))
}


export const useGameStateListener = async () => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector((state)=>state.userInfo)
  const gameRoles = useAppSelector((state) => state.gameRoles)
  //const [unsubscribe,setUnsubscribe] = useState<Unsubscribe|null>(null)
  useEffect(()=>{
    if(!gameRoles.valid){
      updateRoles(dispatch)
      return
    }
    if(userInfo.inGame){
      console.log("Registering game id: ",userInfo.gameId)
      const usb = onSnapshot(doc(gameCollection,userInfo.gameId), (doc) =>{
        console.log("Ciaone")
        const nData = doc.data() as firestoreGame
        console.log(nData)
        const mGame:game = {
            game_id: doc.id,
            join_id: nData.join_id,
            state: nData.state,
            active_vote: nData.active_vote? nData.active_vote.id:null,
            active_ballot: nData.active_ballot? nData.active_ballot.id:null,
            lastWasBallot: nData.lastWasBallot,
            loadState:{
              game:false,
              ballot:false,
              players:false,
              votes:false
            },
            votazioni: {},
            ballottaggi: {},
            players: []
        }
        dispatch(createGameUpdateAction(mGame))
      })
      const playerCollection = collection(db,"games",userInfo.gameId!,"players")
      const ubb = onSnapshot(playerCollection, (docs)=> {
        console.log("Ciaone 2")  
        const mPlayers = docs.docs.map((doc) => {
            
            const mA = doc.data() as firestorePlayer
            console.log(mA,mA.role?.id)
            console.log(gameRoles)
            if(mA.role){
              console.log("Role: ",gameRoles.roles[mA.role.id])
            }
            const t:player = {
              player_id: doc.id,
              amato: mA.amato,
              dead: mA.dead,
              romeo: mA.romeo,
              name: mA.name,
              role: mA.role? gameRoles.roles[mA.role.id] : null
            }
            return t
          })
          //Check for kick
          console.log("KICK CHECK",mPlayers.filter(t=>t.player_id===userInfo.playerId!))
          if(userInfo.isNarratore === false && mPlayers.filter(t=>t.player_id===userInfo.playerId!).length === 0){
            console.log("Dispatching leave action",createUserLeaveAction()())
            dispatch(createUserLeaveAction()())
          }else{
            dispatch(createPlayerUpdateAction(mPlayers))
          }
          
      })
      const votesCollection = collection(db,"games",userInfo.gameId!,"voti")
      const usc = onSnapshot(votesCollection, (docs)=>{
        const mVots:votesMap = {}
        docs.forEach((doc)=> {
          const mA = doc.data() as firestoreVotazione
          const t:votazione = {
            active: mA.active,
            vot_id: doc.id,
            voti: mA.votes.map((val)=>{
              return {
                from: val.from.id,
                to: val.to.id
              }
            }),
            segnalazioni: mA.segnalazioni.map((val)=>{
              return {
                from: val.from.id,
                to: val.to.id
              }
            })
          }
          mVots[doc.id] = t
        })
        dispatch(createVotesUpdateAction(mVots))
      })
      const ballotCollection = collection(db,"games",userInfo.gameId!,"ballottaggi")
      const ubd = onSnapshot(ballotCollection, (docs)=>{
        const mVots:ballotMap = {}
        docs.forEach((doc)=> {
          const mA = doc.data() as firestoreBallottaggio
          const t:ballottaggio = {
            active: mA.active,
            ballot_id: doc.id,
            accusati: mA.accusati.map((val)=>val.id),
            voti: mA.votes.map((val)=>{
              return {
                from: val.from.id,
                to: val.to.id
              }
            }),
            segnalazioni: mA.segnalazioni.map((val)=>{
              return {
                from: val.from.id,
                to: val.to.id
              }
            })
          }
          mVots[doc.id] = t
        })
        dispatch(createBallotUpdateAction(mVots))
      })
      const mUnsb = ()=>{
        console.log("Unregistering subscribers")
        usb();
        ubb();
        usc();
        ubd();
      }
      return mUnsb
    }
  },[dispatch,userInfo,gameRoles])

}


export const updatePlayerRole = async (gameId:string, playerId:string, roleId:string) => {
  const playerDoc = doc(db,"games",gameId,"players",playerId)
  const roleDocRef = doc(db,"roles",roleId)

  return await updateDoc(playerDoc,{
    role:roleDocRef
  })
}

export const kickPlayer = async(gameId:string, playerId:string) => {
  const playerRef = doc(db,"games",gameId,"players",playerId)
  
  const voteRef = collection(db,"games",gameId,"voti")
  const ballotRef = collection(db,"games",gameId,"ballottaggi")

  await (await getDocs(voteRef)).forEach(async val=>{
    const dat = val.data() as firestoreVotazione
    for(let i = 0; i < dat.votes.length; i++){
      if(dat.votes[i].to.id === playerId || dat.votes[i].from.id === playerId){
        await updateDoc(val.ref,{
          votes : arrayRemove(dat.votes[i])
        })
      }
    }
    for(let i = 0; i < dat.segnalazioni.length; i++){
      if(dat.segnalazioni[i].to.id === playerId || dat.segnalazioni[i].from.id === playerId){
        await updateDoc(val.ref,{
          segnalazioni : arrayRemove(dat.segnalazioni[i])
        })
      }
    }

  })

  await (await getDocs(ballotRef)).forEach(async val=>{
    const dat = val.data() as firestoreBallottaggio
    for(let i = 0; i < dat.votes.length; i++){
      if(dat.votes[i].to.id === playerId || dat.votes[i].from.id === playerId){
        await updateDoc(val.ref,{
          votes : arrayRemove(dat.votes[i])
        })
      }
    }
    for(let i = 0; i < dat.segnalazioni.length; i++){
      if(dat.segnalazioni[i].to.id === playerId || dat.segnalazioni[i].from.id === playerId){
        await updateDoc(val.ref,{
          segnalazioni : arrayRemove(dat.segnalazioni[i])
        })
      }
    }

    
  })

  return await deleteDoc(playerRef)
}

export const updatePlayerStatus = async(gameId:string, playerId:string, dead:boolean, romeo:boolean, amato:boolean) => {
  const playerRef = doc(db,"games",gameId,"players",playerId)
  return await updateDoc(playerRef,{
    dead,amato,romeo
  }
  )
}

export const updateGameState = async(gameId:string, gState:gameState.GSTATE_FINE|gameState.GSTATE_NOTTE|gameState.GSTATE_OPEN) => {
  const gameRef = doc(db,"games",gameId)
  return await updateDoc(gameRef,{
    state: gState
  })
}

export const startNewVotes = async(gameId:string) => {
    const nVotesStruct: firestoreVotazione = {
      active: true,
      votes: [],
      segnalazioni: []
    };

    const votesRef = await addDoc(collection(db,"games",gameId,"voti"),nVotesStruct)

    const gameRef = doc(db,"games",gameId)

    await updateDoc(gameRef,{
      active_vote:votesRef,
      state: gameState.GSTATE_VOTI,
      lastWasBallot:false
    })
    return votesRef
}

export const castVote = async(gameId:string, votesId:string, playerId:string, targetId:string) =>{
  const voteRef = doc(db,"games",gameId,"voti",votesId)

  const fromRef = doc(db,"games",gameId,"players",playerId)
  const toRef = doc(db,"games",gameId,"players",targetId)

  await updateDoc(voteRef,{
      votes: arrayUnion({from:fromRef,to:toRef} as firestoreVoto)
  })
}

export const startNewBallot = async(gameId:string,accusati:string[]) => {
  const nVotesStruct: firestoreBallottaggio = {
    accusati: accusati.map(t=>doc(db,"games",gameId,"players",t)),
    active: true,
    votes: [],
    segnalazioni: []
  };

  const ballotRefs = await addDoc(collection(db,"games",gameId,"ballottaggi"),nVotesStruct)

  const gameRef = doc(db,"games",gameId)

  await updateDoc(gameRef,{
    active_ballot:ballotRefs,
    state: gameState.GSTATE_BALLOT,
    lastWasBallot:true
  })
  return ballotRefs
}

export const castBallotVote = async(gameId:string, votesId:string, playerId:string, targetId:string) =>{
  const voteRef = doc(db,"games",gameId,"ballottaggi",votesId)

  const fromRef = doc(db,"games",gameId,"players",playerId)
  const toRef = doc(db,"games",gameId,"players",targetId)

  await updateDoc(voteRef,{
      votes: arrayUnion({from:fromRef,to:toRef} as firestoreVoto)
  })
}

export const castSegn = async(gameId:string, isBallot:boolean, votesId:string, playerId:string, targetId:string)=>{
  const fromRef = doc(db,"games",gameId,"players",playerId)
  const toRef = doc(db,"games",gameId,"players",targetId)
  let voteRef:DocumentReference
  if(isBallot){
    voteRef = doc(db,"games",gameId,"ballottaggi",votesId)
  }else{
    voteRef = doc(db,"games",gameId,"voti",votesId) 
  }
  await updateDoc(voteRef,{
    segnalazioni: arrayUnion({from:fromRef,to:toRef} as firestoreVoto)
})
}

export const joinGameAsAdmin = async (dispatch:AppDispatch,join_id:string) =>{
  const q = query(gameCollection,where("join_id","==",join_id))
  const querySnapshot = await getDocs(q)

  if (querySnapshot.size !== 1){
    throw new InvalidIdError("Invalid id")
  }
  const game = querySnapshot.docs[0]


  dispatch(createUserCreateAction(game.id))
 
  
}
