import React, { useState } from 'react';
import './App.css';
import { Button, CssVarsProvider, FormControl, FormLabel, Input, Sheet, Typography } from '@mui/joy';
import { useAppDispatch, useAppSelector } from './store';
import { GameCloseError, InvalidIdError, joinGame, newGame } from './backend';
import { Navigate, useNavigate } from 'react-router-dom';


function App() {
  const [joinId, setJoinId] = useState("");
  const [name, setName] = useState("");

  const appDispatch = useAppDispatch()
  const navigate = useNavigate()

  const inGame = useAppSelector((state)=>state.userInfo.inGame)

  const createGame = async () => {
    await newGame(appDispatch)
    navigate("/game")
  }
  const joinGameBtn = async () =>{
    try{
      await joinGame(appDispatch,joinId.trim(),name.trim())
      navigate("/game")
    }catch(ert){
      if(ert instanceof InvalidIdError){
        alert("Impossibile trovare la partita")
      }else if(ert instanceof GameCloseError){
        alert("Non è possibile entrare in una partita in corso")
      }else{
        throw(ert)
      }
      
    }
  }
  if(inGame){
    return <Navigate to="/game"/>
  }
  return (
    <CssVarsProvider>
      <main>
        <Sheet
          sx={{
            width: 300,
            mx: 'auto', // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 'sm',
            boxShadow: 'md',
          }}
          variant="outlined"
        >
          <div>
            <Typography level="h4" component="h1">
              <b>Ciaone!</b>
            </Typography>
            <Typography level="body-sm">Crea nuova partita o inserisci l'ID di una partita in corso</Typography>
          </div>
          <FormControl>
            <FormLabel>Join-id</FormLabel>
            <Input
              // html input attribute
              name="join_id"
              type="text"
              placeholder="ABCDEFG"
              value = {joinId}
              onChange= {(e) => setJoinId(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input
              // html input attribute
              name="name"
              type="text"
              placeholder="pippo"
              value = {name}
              onChange= {(e) => setName(e.target.value)}
            />
          </FormControl>

          <Button sx={{ mt: 1 /* margin top */ }} onClick = {joinGameBtn}>Join Game</Button>
          <Button sx={{ mt: 1 /* margin top */ }} onClick = {createGame}>Create Game</Button>
        </Sheet>
      </main>
    </CssVarsProvider>
  );
}

export default App;
