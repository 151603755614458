import { Box, Button, Modal, ModalDialog } from '@mui/joy'
import React from 'react'
import QRCode from 'react-qr-code'

interface QRModalProps{
    open:boolean,
    code:string,
    onClose?:()=>void
}

export const QRModal = ({open,code,onClose}:QRModalProps)=>{
    const mFunc = ()=>{
        if(onClose){
            onClose()
        }
    }
    return <Modal open={open} onClose={mFunc}>
    <ModalDialog
      variant="outlined"
      role="alertdialog"
      aria-labelledby="alert-dialog-modal-title"
      aria-describedby="alert-dialog-modal-description"
    >
    <QRCode value={code} />
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
        <Button variant="plain" color="neutral" onClick={mFunc}>
          Chiudi
        </Button>
      </Box>
    </ModalDialog>
  </Modal>
}