import { Button, ButtonGroup } from '@mui/joy'
import React from 'react'
import { gameState } from './gameState'


interface NarretorControlsProps{
    cState:gameState,
    onPushButton:(newState:gameState)=>void
}



export const NarretorControls = ({cState,onPushButton}:NarretorControlsProps)=>{
    const callback = (nState:gameState)=>{
        if(onPushButton){
            onPushButton(nState)
        }
    }
    return (
    <ButtonGroup aria-label="outlined primary button group">
        <Button disabled={cState===gameState.GSTATE_OPEN} onClick={()=>callback(gameState.GSTATE_OPEN)}>Aperto</Button>
        <Button disabled={cState===gameState.GSTATE_NOTTE} onClick={()=>callback(gameState.GSTATE_NOTTE)}>Notte/Assemblea cittadina</Button>
        <Button disabled={cState===gameState.GSTATE_VOTI} onClick={()=>callback(gameState.GSTATE_VOTI)}>Votazioni</Button>
        <Button disabled={cState===gameState.GSTATE_BALLOT} onClick={()=>callback(gameState.GSTATE_BALLOT)}>Ballottaggio</Button>
        <Button disabled={cState===gameState.GSTATE_FINE} onClick={()=>callback(gameState.GSTATE_FINE)}>Fine</Button>
    </ButtonGroup>)
}