import { Autocomplete, Box, Button, Divider, Modal, ModalDialog, Typography } from '@mui/joy'
import React, { useState } from 'react'
import { game } from './gameState'

interface ConfirmModalProps{
    open:boolean,
    cGameState:game,
    title?:string
    onConfirm?: (selected:string[])=>void
    onCancel?: ()=>void
}

export const BallotModal = ({open,cGameState,onConfirm, onCancel,title="WolfApp"}:ConfirmModalProps)=>{
    const [vals,setVals] = useState([] as {label:string,id:string}[])

    const opts = cGameState.players.map(pt=>{
        return {label:`${pt.name}(${pt.role?pt.role.name:"No Role"})`, id:pt.player_id}
    })
    return <Modal open={open} onClose={onCancel}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            level="h2"
          >
            {title}
          </Typography>
          <Divider />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            Seleziona accusati per il ballottaggio
          </Typography>
          <Autocomplete
                multiple
                placeholder="Accusati"
                options={opts}
                value={vals}
                onChange={(_,val)=>{
                    setVals(val as {label:string,id:string}[] )
                }}
            />
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={()=>{
                if(onConfirm){
                    onConfirm(vals.map(t=>t.id))
                }
            }}>
              Avvia ballottaggio
            </Button>
            <Button variant="solid" color="danger" onClick={onCancel}>
              Annulla
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
}