import React from 'react'
import { mapFactionToText, player, playerRole } from './gameState';
import { Autocomplete, Avatar, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardOverflow, Checkbox, Chip, List, ListItem, Typography } from '@mui/joy';

export interface commonVoteType {
    playerName:string, playerRole:string, sepcial:boolean,
}

export interface segnVoteType {
    playerName:string, playerRole:string
}

interface PlayerCardVotesProps{
    votes?:commonVoteType[],
    segns?:segnVoteType[],
    isNarretor?:boolean
}


export function PlayerCardVotes({votes,segns,isNarretor=false}: PlayerCardVotesProps){
    return (
    <List aria-labelledby="basic-list-demo">
        {segns&&isNarretor?segns.map(segn=>{
            return (
                <ListItem>
                    <Chip color="success">Segnalato da {segn.playerName}({segn.playerRole})</Chip>
                </ListItem>
            )
        }):null}
        {votes?votes.map(vote=>{
            return (
                <ListItem>
                    <Chip color= {vote.sepcial&&isNarretor?"danger":"neutral"}>Voto da {vote.playerName}{isNarretor?`(${vote.playerRole})`:null}</Chip>
                </ListItem>
            )
        }):null}
    </List>)

}


interface PlayerCardProps{
    mplayer:player,
    isNarretor?:boolean,
    disableEdit?:boolean,
    disableVote?:boolean,
    canSegn?:boolean,
    canSeeVotes?:boolean,
    disableSegn?:boolean,
    segnButtonText?:string,
    isAccusato?:boolean,
    isSegnalato?:boolean,
    isYou?:boolean,
    isVotato?:boolean,
    isSpy?:boolean,
    roles?:{[s in string]: playerRole},
    votes?:commonVoteType[],
    segns?:segnVoteType[]
    onChangeRole?:(newRole:playerRole)=>void,
    onKick?:()=>void,
    onVote?:()=>void,
    onSegn?:()=>void,
    onStatusUpdate?:(dead:boolean,romeo:boolean,amato:boolean)=>void
}

export function PlayerCard({mplayer,votes,segns,onChangeRole,onKick,onStatusUpdate,onVote,onSegn,isSpy=false,segnButtonText="Segnala",isSegnalato=false,disableSegn=false,canSegn=false,isAccusato=false,disableVote=true,isVotato=false,isYou=false,disableEdit = true, isNarretor = false,roles = {},canSeeVotes=isNarretor}:PlayerCardProps){
    const rolesSelect = Object.keys(roles).map(key=>{
        return {
            label: roles[key].name,
            id:roles[key].role_id
        }
    })

    const voteBox = canSeeVotes?(<div>
        {votes?        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
            {`Voti totali: ${votes.length}`}
        </Typography>:null}
        <PlayerCardVotes votes={votes} segns={segns} isNarretor={isNarretor}/>
    </div>):null

    const roleBox = mplayer.role?(<div>
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
            {mplayer.role.mystic? "Mistico":"No misticismo"}
        </Typography>
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
            {mplayer.role.dark_aura? "Aura oscura":"Aura bianca"}
        </Typography>
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
            {mapFactionToText(mplayer.role.faction)}
        </Typography>
        </div>):null



    const narretorBox = (<div>
        <Autocomplete
            placeholder="Ruolo"
            value={mplayer.role?{label:mplayer.role.name,id:mplayer.role.role_id}:{label:"Ruolo",id:"null"}}
            disabled={disableEdit}
            options={rolesSelect}
            onChange={(e,newValue)=>{
                if(newValue && onChangeRole){
                    onChangeRole(roles[newValue.id])
                }
            }}
            sx={{ width: 300 }}
        />
        {roleBox}
    </div>)

    return (<Card
            sx={{
            width: 320,
            maxWidth: '100%',
            boxShadow: 'lg',
            }}
        >
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Avatar sx={{ '--Avatar-size': '4rem' }} />
            {isYou?<Chip
                size="sm"
                variant="soft"
                color="primary"
                sx={{
                mt: -1,
                mb: 1,
                border: '3px solid',
                borderColor: 'background.surface',
                }}
            >
                SEI TU!
            </Chip>:null}
            {isVotato?<Chip
                size="sm"
                variant="soft"
                color="danger"
                sx={{
                mt: -1,
                mb: 1,
                border: '3px solid',
                borderColor: 'background.surface',
                }}
            >
                VOTATO!
            </Chip>:null}
            {isSegnalato?<Chip
                size="sm"
                variant="soft"
                color="danger"
                sx={{
                mt: -1,
                mb: 1,
                border: '3px solid',
                borderColor: 'background.surface',
                }}
            >
                SEGNALATO!
            </Chip>:null}
            {isAccusato?<Chip
                size="sm"
                variant="soft"
                color="warning"
                sx={{
                mt: -1,
                mb: 1,
                border: '3px solid',
                borderColor: 'background.surface',
                }}
            >
                ACCUSATO!
            </Chip>:null}

            <Typography level="title-lg">{mplayer.name}</Typography>
            {
                
                (isNarretor||isYou)?narretorBox:null
            }
            <Box
                sx={{
                display: 'flex',
                gap: 2,
                mt: 2,
                '& > button': { borderRadius: '2rem' },
                }}
            >
                <Checkbox label="Morto" variant="outlined" disabled={disableEdit} checked={mplayer.dead} onChange={()=>{
                    if(onStatusUpdate){
                        onStatusUpdate(!mplayer.dead,mplayer.romeo,mplayer.amato)
                    }
                }}/>
                {isNarretor?
                    <Checkbox label="Amato" variant="outlined" disabled={disableEdit} checked={mplayer.amato} onChange={()=>{
                        if(onStatusUpdate){
                            onStatusUpdate(mplayer.dead,mplayer.romeo,!mplayer.amato)
                        }
                    }}/>:null
                }
                {isNarretor?
                    <Checkbox label="Romeo" variant="outlined" disabled={disableEdit} checked={mplayer.romeo} onChange={()=>{
                        if(onStatusUpdate){
                            onStatusUpdate(mplayer.dead,!mplayer.romeo,mplayer.amato)
                        }
                    }}/>:null
                }
            </Box>
            <Box>
                {voteBox}
            </Box>
            </CardContent>
            {isNarretor?<CardOverflow sx={{ bgcolor: 'background.level1' }}>
            <CardActions buttonFlex="1">
                <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
                    <Button disabled={disableEdit} onClick={onKick}>Kick</Button>
                </ButtonGroup>
            </CardActions>
            </CardOverflow>:<CardOverflow sx={{ bgcolor: 'background.level1' }}>
            <CardActions buttonFlex="1">
                <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
                    <Button disabled={disableVote} onClick={onVote}>{isSpy&&isYou?"Attiva potere Spia":"Vota"}</Button>
                    {canSegn?<Button disabled={disableSegn} onClick={onSegn}>{segnButtonText}</Button>:null}
                </ButtonGroup>
            </CardActions>
            </CardOverflow>}
        </Card>)
}