import { PayloadAction, configureStore, createAction, createSlice } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { gameRolesSlice, gameStateSlice } from "./gameState";

export interface UserInfoState{
    isNarratore:boolean,
    inGame:boolean
    gameId?:string,
    playerId?:string
}

export interface UserJoinActionPayload{
    gameId:string,
    playerId:string
}

export const UserJoinAction = createAction<UserJoinActionPayload>("USER_JOIN")
export const UserCreateAction = createAction<string>("USER_CREATE")
export const UserLeaveAction = createAction("USER_LEAVE")


export const userInfoSlice = createSlice({
    name:"userInfo",
    initialState: {
        isNarratore:false,
        inGame:false
    } as UserInfoState,
    reducers: {
        join: (state,action:PayloadAction<UserJoinActionPayload>) => {
            state.isNarratore = false
            state.inGame = true
            state.gameId = action.payload.gameId
            state.playerId = action.payload.playerId
        },
        create: (state,action:PayloadAction<string>) => {
            state.isNarratore = true
            state.gameId = action.payload
            state.playerId = undefined
            state.inGame = true
        },
        leave: (state) => {
            console.log("Reduicing leave action")
            state.isNarratore = false
            state.gameId = undefined
            state.playerId = undefined
            state.inGame = false
        }
    }
})

export const counterSlice = createSlice({
    name:"counter",
    initialState: {
        value:0
    },
    reducers:{
        increment:(state) =>{
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        }
    }
})

export const {increment,decrement} = counterSlice.actions


export const createUserJoinAction = (gameId:string, playerId:string) => {
    return userInfoSlice.actions.join({
        gameId,playerId
    })
}
export const createUserCreateAction = (gameId:string) => {
    return userInfoSlice.actions.create(gameId)
}
export const createUserLeaveAction = () => {
    return userInfoSlice.actions.leave
}

const persistConfig = {
    key: 'root',
    storage,
  }


const persistedReducer = persistReducer(persistConfig,userInfoSlice.reducer)

export const store = configureStore({
    reducer:{
        userInfo:persistedReducer,
        gameState:gameStateSlice.reducer,
        counter:counterSlice.reducer,
        gameRoles: gameRolesSlice.reducer
    }
})

export const persistor = persistStore(store)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector