import { CircularProgress} from '@mui/joy'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {joinGameAsAdmin } from './backend'
import { useAppDispatch } from './store'


export const JoinPageAdmin = ()=>{
    const {joinId} = useParams()

    const appDispatch = useAppDispatch()

    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)

    useEffect(()=>{
        if(!joinId){
            setError(true)
            return;
        }
       joinGameAsAdmin(appDispatch,joinId).then(()=>{
        setLoading(false)
       }).catch(err=>{
        setError(true)
       })
    },[joinId,appDispatch])
  

    if(error){
        return <Navigate to="/"/>
    }
  
    if(loading){
        return <CircularProgress/>
    }
    
    return <Navigate to="/game"/> 

}