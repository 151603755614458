import { Box, Button, Divider, Modal, ModalDialog, Typography } from '@mui/joy'
import React from 'react'

interface ConfirmModalProps{
    open:boolean,
    title?:string,
    message:string
    confirmText?:string,
    cancelText?:string
    onConfirm?: ()=>void
    onCancel?: ()=>void
}

export const ConfirmModal = ({open,message,onConfirm, onCancel,title="WolfApp",confirmText="Ok",cancelText="Annulla"}:ConfirmModalProps)=>{
    return <Modal open={open} onClose={onCancel}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            level="h2"
          >
            {title}
          </Typography>
          <Divider />
          <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
            {message}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
            <Button variant="plain" color="neutral" onClick={onConfirm}>
              {confirmText}
            </Button>
            <Button variant="solid" color="danger" onClick={onCancel}>
              {cancelText}
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
}