import React, { PropsWithChildren } from 'react'
import { useAppSelector } from './store'
import { CircularProgress } from '@mui/joy'

export const LoadGuard = (props:PropsWithChildren) => {
    const loadState = useAppSelector((state)=>state.gameState.loadState)
    if(loadState.ballot && loadState.game && loadState.players && loadState.votes){
        return (<React.Fragment>{props.children}</React.Fragment>)
    }else{
        return (<React.Fragment><CircularProgress /></React.Fragment>)
    }
}