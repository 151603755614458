import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export enum gameState{
    GSTATE_OPEN,
    GSTATE_NOTTE,
    GSTATE_VOTI,
    GSTATE_BALLOT,
    GSTATE_FINE
}

export const mapGameStateToText = (state:gameState) => {
    switch(state){
        case gameState.GSTATE_OPEN:
            return "Aperto"
        case gameState.GSTATE_BALLOT:
            return "Ballottaggio"
        case gameState.GSTATE_FINE:
            return "Partita conclusa"
        case gameState.GSTATE_NOTTE:
            return "Notte/Assemblea cittadina"
        case gameState.GSTATE_VOTI:
            return "Votazione"
        default:
            return "Errore"
    }
}

export enum gameFaction{
    GFACTION_VILLAGGIO,
    GFACTION_CRIMINALI,
    GFACTION_CITTA,
    GFACTION_LUPI,
    GFACTION_VAMPIRO,
    GFACTION_AMANTI,
    GFACTION_OTHER
}

export enum roleSpecial{
    GSPECIAL_NONE,
    GSPECIAL_SPY,
    GSPECIAL_MERC,
    GSPECIAL_ORAT,
    GSPECIAL_AZZEC
}

export const mapFactionToText = (faction:gameFaction) => {
    switch(faction){
        case gameFaction.GFACTION_AMANTI:
            return "Amanti"
        case gameFaction.GFACTION_CITTA:
            return "Città"
        case gameFaction.GFACTION_CRIMINALI:
            return "Criminali"
        case gameFaction.GFACTION_LUPI:
            return "Lupi del branco"
        case gameFaction.GFACTION_VAMPIRO:
            return "Vampiri"
        case gameFaction.GFACTION_VILLAGGIO:
            return "Villaggio"
        case gameFaction.GFACTION_OTHER:
            return "Altro"
        default:
            return "ERRORE"
    }
}

export interface playerRole{
    role_id:string,
    name:string,
    dark_aura:boolean,
    mystic:boolean,
    faction:gameFaction,
    always_vote_ballot:boolean,
    special_ballot:boolean,
    special_vote:boolean,
    special:roleSpecial
}

export interface player{
    player_id:string,
    name:string,
    role:playerRole|null,
    dead:boolean,
    amato:boolean,
    romeo:boolean

}

export interface voto{
    from:string,
    to:string
}

export interface votazione{
    vot_id:string,
    active:boolean
    voti:voto[],
    segnalazioni:voto[]
}

export interface ballottaggio{
    ballot_id:string,
    active:boolean,
    voti:voto[],
    accusati:string[],
    segnalazioni:voto[]
}

export type votesMap = {[k in string]: votazione}
export type ballotMap = {[k in string]: ballottaggio}

export interface game{
    game_id:string,
    join_id:string,
    state: gameState,
    active_vote:string|null,
    active_ballot:string|null,
    votazioni: votesMap,
    ballottaggi: ballotMap,
    lastWasBallot:boolean,
    players: player[],
    loadState:{
        game:boolean,
        ballot:boolean,
        players:boolean,
        votes:boolean
    }
}

export interface gameRoles{
    roles: {[K:string]: playerRole},
    valid:boolean
}

export const gameRolesSlice = createSlice({
    name:"gameRoles",
    initialState: {
        roles:{},
        valid:false
    } as gameRoles,
    reducers: {
        setRole: (state,action:PayloadAction<playerRole>) => {
           
            state.roles[action.payload.role_id] = action.payload
            
            state.valid = true
        },
        setRoles: (state,action:PayloadAction<playerRole[]>) => {
            action.payload.forEach(elm=>{
                state.roles[elm.role_id] = elm
            })
            state.valid = true
        },
        deleteRole: (state,action:PayloadAction<string>) => {
            if(state.valid === false){
                return
            }
            delete state.roles[action.payload]
            if(Object.keys(state.roles).length === 0){
                state.valid = false
            }
        }
    }
})

export const createGameSetRoleAction = (nRole:playerRole) => {
    return gameRolesSlice.actions.setRole(nRole)
}

export const createGameSetRolesAction = (nRoles:playerRole[]) => {
    return gameRolesSlice.actions.setRoles(nRoles)
}

export const createGameDeleteRoleAction = (id:string) => {
    return gameRolesSlice.actions.deleteRole(id)
}

export const gameStateSlice = createSlice({
    name:"gameState",
    initialState: {
        game_id:"",
        join_id:"",
        state:gameState.GSTATE_FINE,
        players: [],
        active_vote: null,
        active_ballot:null,
        ballottaggi: {},
        lastWasBallot:false,
        votazioni: {},
        loadState:{
            game:false,
            ballot:false,
            players:false,
            votes:false
        }
    } as game,
    reducers: {
        updateGame: (state,action:PayloadAction<game>) => {
            state.game_id = action.payload.game_id
            state.join_id = action.payload.join_id
            state.state = action.payload.state
            state.active_vote = action.payload.active_vote
            state.active_ballot = action.payload.active_ballot
            state.lastWasBallot = action.payload.lastWasBallot
            state.loadState.game = true;
        },
        updatePlayers: (state,action:PayloadAction<player[]>) => {
            state.players = action.payload
            state.loadState.players = true;
        },
        updateVotes: (state,action:PayloadAction<votesMap>) => {
            state.votazioni = action.payload
            state.loadState.votes = true;
        },
        updateBallot: (state,action:PayloadAction<ballotMap>) =>{
            state.ballottaggi = action.payload
            state.loadState.ballot = true
        },
        refreshState: (state) => {
            state.loadState.ballot = false;
            state.loadState.players = false;
            state.loadState.game = false;
            state.loadState.votes = false;
        }
    }
})

export const createGameUpdateAction = (nGame:game) => {
    return gameStateSlice.actions.updateGame(nGame)
}

export const createPlayerUpdateAction = (players:player[]) => {
    return gameStateSlice.actions.updatePlayers(players)
}

export const createVotesUpdateAction = (votes:votesMap) => {
    return gameStateSlice.actions.updateVotes(votes)
}

export const createBallotUpdateAction = (ballot:ballotMap) => {
    return gameStateSlice.actions.updateBallot(ballot)
}

export const createRefreshStateAction = () =>{
    return gameStateSlice.actions.refreshState()
}